
// АНИМАЦИЯ НА САЙТЕ
//=============================================================================
@speed: 0.3s;
.animation(@param) {
  animation:          @param;
}
.anim_on(@speed) {
  transition:           all @speed ease-out;
}
.anim_off {
  transition:           none !important;
}

// DELAY (0.4s)
@delay: 0.5s;
.anim-delay(@delay) {
  transition-delay:             @delay;
}

// BACKGROUND SIZE
.bgs(@bgs) {
  background-size: @bgs; /* браузеры с поддержкой CSS3: FF ≥ 4, Opera ≥ 10.53, IE ≥ 9.0 */
}

// TRANSFORM
.transform(@param) {
  transform: @param;
}

.origin(@param) {
  transform-origin: @param;
}

// SHADOW
@shadow: 0 4px 20px -2px rgba(@color_b1, 0.08);
@shadow_s: 0 3px 13px -2px rgba(@color_b1, 0.15);

.shadow(@shadow) {
  box-shadow: @shadow;
}