.svg-sprites {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

// ---------------------------------- MAIN PAGE
// Section main banner
.section__main-banner {
  max-height: 560px;
  height: 70vh;
  min-height: 380px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: url("@{img}pestec-main-bg.jpg") 50% 50% no-repeat;
  background-size: cover !important;
  position: relative;

  &.main-banner-mosquitosf{
    background: url("@{img}mosquito-sf-bg.jpg") 50% 50% no-repeat;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(@color_b1, .5);
    position: absolute;
    z-index: 1;
  }

  .welcome-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: @color_w;
    max-width: 600px;
    margin: auto;
    text-align: center;
    padding-left: @gap;
    padding-right: @gap;

    h1 {
      font-size: 35px;
      font-weight: @f_bold;
      line-height: 110%;
      margin-bottom: @gap;
    }
    p{
      margin-bottom: @gap;
    }

    h2 {
      font-size: 20px;
      font-weight: @f_reg;
      line-height: 110%;
      margin-bottom: @gap;
    }

    .main-pests-list {
      display: flex;
      flex-wrap: nowrap;
      margin-top: @gap*2;
      margin-bottom: @gap*2;

      > * {
        text-align: center;
        width: calc(100% / 5);
        flex: 1 0 auto;

        > svg {
          width: 70px;
          height: 70px;
          fill: @color_w;
        }

        p {
          font-size: 13px;
        }
      }
    }
  }

  @media only screen and (max-width: 500px),
  only screen and (max-device-width: 500px) {
    .welcome-content {
      h1 {
        font-size: 25px;
      }

      h2 {
        font-size: 15px;

      }

      .main-pests-list {
        flex-wrap: wrap;
        justify-content: space-around;

        > * {
          > svg {
            width: 50px;
            height: 50px;
          }
          p{
            display: none;
          }
        }
      }
    }
  }
}


// Section fact list
.section__fact-list {
  background: @color_b7;
  padding-top: @gap*3;
  padding-bottom: @gap*3;

  .fact-list {
    justify-content: space-around;

    &__item {
      max-width: 200px;
      text-align: center;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0;
      margin-bottom: 0;
    }

    &__icn {
      margin-bottom: 10px;

      > svg {
        width: 100px;
        height: 100px;
        fill: @color_1;
      }
    }

    &__hn {
      font-size: 15px;
      font-weight: @f_sbold;
      line-height: 110%;
      .caps;
      margin-bottom: 10px;
    }

    &__shn {
      font-size: 13px;
      font-weight: @f_reg;
      line-height: 120%;
    }
  }

  @media only screen and (max-width: 760px),
  only screen and (max-device-width: 760px) {

    .fact-list {
      flex-wrap: wrap;

      &__item {
        max-width: none;
        width: 50%;
        margin-bottom: @gap*2;
      }

      &__icn {
        margin-bottom: 10px;

        > svg {
          width: 60px;
          height: 60px;
        }
      }

      &__hn,
      &__shn {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: @gap;
        padding-right: @gap;
      }
    }
  }
}

// Section pest control
.section__pest-control {
  padding-top: @gap*2;
  padding-bottom: @gap*2;

  @media only screen and (max-width: 760px),
  only screen and (max-device-width: 760px) {
    .columns_2 {
      column-count: 1;
      column-gap: 40px;
    }
  }
}

.big-links {
  margin-left: -@gap/2;
  margin-right: -@gap/2;

  > * {
    padding-left: @gap/2;
    padding-right: @gap/2;

    a {
      display: flex;
      width: 100%;
      border-radius: @br;
      position: relative;
      height: 260px;
      transition: all .28s ease-out;
      .box-shadow;
      margin-bottom: 15px;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background: rgba(@color_b1, .5);
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover !important;
        transition: all .28s ease-out;
      }

      > div {
        //border: 1px solid red !important;
        position: absolute;
        z-index: 3;
        color: @color_w;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        text-align: center;

        strong {
          font-size: 35px;
          display: block;
          text-align: center;
          .caps;
          white-space: nowrap;
          margin-bottom: 10px;
          padding-bottom: 13px;
          position: relative;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            height: 2px;
            width: 70px;
            left: 50%;
            transform: translateX(-50%);
            background: @color_1;
          }
        }

        p {
          font-size: 17px;
          line-height: 110%;
          font-weight: @f_reg;
          margin-bottom: 0;
        }

        .big-link__arrow {
          display: block;
          text-align: center;
          position: absolute;
          bottom: -@gap*3;
          text-indent: -30px;
          opacity: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all .2s ease-out;

          &::after {
            content: '\f178';
            font-size: 30px;
            .fal;
            color: rgba(@color_w, .7);
          }
        }
      }

      &:hover {
        &::after {
          filter: blur(3px);
          transform: scale(1.1);
        }

        .big-link__arrow {
          text-indent: 0;
          opacity: 1;
        }
      }
    }

    @media only screen and (max-width: 1024px),
    only screen and (max-device-width: 1024px) {
      width: 100% !important;
      display: flex;

      > a{
        flex: 1 0 320px;
        margin-right: @gap;
        height:180px;
      }

    }
    @media only screen and (max-width: 760px),
    only screen and (max-device-width: 760px) {
      flex-direction: column;
      > a{
        flex: 1 1 auto;
        margin-right: auto;
        height:180px;
      }

    }
  }

  .big-link_residential {
    &::after {
      background-image: url("@{img}image4.jpg");
    }
  }

  .big-link_government {
    &::after {
      background-image: url("@{img}img-main-government.jpg");
    }
  }

  .big-link__commercial {
    &::after {
      background-image: url("@{img}img-main-commercial.jpg");
    }
  }

  @media only screen and (max-width: 760px),
  only screen and (max-device-width: 760px) {
    flex-direction: column;

    > *{
      width: 100% !important;
      &:not(:last-child){
        margin-bottom: @gap;
      }
    }

  }
}

// Slider Reviews
.section__reviews {
  background: url("@{img}bg-reviews.jpg") 50% 50% no-repeat;
  background-size: cover !important;
  padding-top: @gap*2;
  padding-bottom: @gap*2;

  .title > * {
    text-align: center;
    color: @color_w;
    font-size: 35px;
    font-weight: @f_bold;
    text-transform: capitalize;
    margin-bottom: @gap;
  }

  .reviews-slider {
    display: flex;
    margin: auto;
    max-width: 680px;
    position: relative;

    .item {
      background: @color_w;
      border-radius: @br;
      .box-shadow;
      padding: @gap*2;
      overflow: hidden;

      .review-decor {
        position: absolute;
        top: 15px;
        left: -32px;
        opacity: .5;

        svg {
          width: 60px;
          height: 54px;
        }
      }

      .review-name {
        font-size: 25px;
        margin-bottom: @gap;
      }

      .review-date {
        position: absolute;
        right: @gap*2;
        top: @gap*2;
        font-size: 15px;
        color: @color_b4;
      }
    }

    .slick-next {
      right: -22px;
      backdrop-filter: blur(5px) !important;
    }

    .slick-prev {
      left: -22px;
      backdrop-filter: blur(5px) !important;
    }
  }

  @media only screen and (max-width: 760px),
  only screen and (max-device-width: 760px) {
    .reviews-slider {
      .item {

        .review-text{
          font-size: 15px;
          line-height: 120%;
        }
      }

      .slick-next {
        right: -18px;
      }

      .slick-prev {
        left: -18px;
      }
    }

  }
}

// Slider Partners
.section__partners {
  padding-top: @gap*3;
  padding-bottom: @gap*3;

  .partners__list {
    .partners__item {
      display: flex;
      align-items: center;
      align-content: center;
      position: relative;

      img{
        max-width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 660px),
  only screen and (max-device-width: 660px) {
    .partners__list {
      .partners__item {
        width: 33.33%;
      }
    }
  }
  @media only screen and (max-width: 460px),
  only screen and (max-device-width: 460px) {
    .partners__list {
      .partners__item {
        width: 50%;
      }
    }
  }
}

// ---------------------------------- ABOUT US
// Section about us
.section__about-us {
  padding-top: @gap;
  padding-bottom: @gap;
}

.pestec-team {
  display: flex;
  flex-wrap: wrap;
  margin-right: -@gap/2;
  margin-left: -@gap/2;

  > .item {
    width: calc(100% / 4);
    display: flex;
    justify-content: stretch;
    padding-right: @gap/2;
    padding-left: @gap/2;

    > div {
      margin: 0 auto @gap;
      display: flex;
      width: 100%;
      flex-direction: column;
      border-radius: @br;
      position: relative;
      padding-bottom: @gap/2;
      .box-shadow;

      .employee-photo {
        width: 98%;
        padding-bottom: 100%;
        overflow: hidden;
        border-radius: 100%;
        position: relative;
        margin: 2px auto 5px auto;
        background: url("@{img}no_img.jpg") 50% 50% no-repeat;
        background-size: cover !important;

        img {
          position: absolute;
          left: 0;
          right: 0;
          -o-object-fit: cover !important;
          object-fit: cover !important;
          width: 100%;
          height: 100%;
          font-family: 'object-fit: cover;';
          transition: all .2s ease-out;
          filter: grayscale(40%);
        }

        &:hover {
          img {
            transform: scale(1.08);
            filter: grayscale(0);
          }
        }
      }

      .employee-name {
        font-size: 15px;
        line-height: 100%;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 5px;
      }

      .employee-position {
        font-size: 13px;
        line-height: 110%;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        color: @color_b2;
      }

      .employee-soc-networks {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 10px;
        right: 10px;
        z-index: 3;

        > * {
          width: 20px;
          height: 20px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          color: white;
          border-radius: 100%;
          background: @color_b3;
          margin-bottom: 3px;
          text-decoration: none;


          i {
            line-height: 0 !important;
          }

          i::before {
            font-size: 11px;
          }

          &:hover {
            background: @color_1;

          }
        }
      }
    }
  }

  @media only screen and (max-width: 680px),
  only screen and (max-device-width: 680px) {
    > .item {
      width: calc(100% / 3);
    }
  }

  @media only screen and (max-width: 520px),
  only screen and (max-device-width: 520px) {
    > .item {
      width: calc(100% / 2);
    }
  }
}


// ---------------------------------- LOCATIONS
// Brach Locations
.section__branch-locations {
  padding-top: @gap;
  padding-bottom: @gap;
}

.locations {
  display: flex;
  flex-wrap: wrap;
  margin-left: -@gap/2;
  margin-right: -@gap/2;

  .item {
    width: 33.33%;
    padding-right: @gap/2;
    padding-left: @gap/2;

    > a {
      position: relative;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      text-align: center;
      .box-shadow;
      color: @color_b1;
      border-radius: @br;
      margin-bottom: @gap;

      .location__photo {
        width: 100%;
        padding-bottom: 80%;
        overflow: hidden;
        border-radius: @br @br 0 0;
        position: relative;
        margin: 2px auto 13px auto;
        background: url("@{img}no_img.jpg") 50% 50% no-repeat;
        background-size: cover !important;

        img {
          position: absolute;
          left: 0;
          right: 0;
          -o-object-fit: cover !important;
          object-fit: cover !important;
          width: 100%;
          height: 100%;
          font-family: 'object-fit: cover;';
          transition: all .2s ease-out;
          filter: grayscale(40%);
        }
      }

      .location__region {
        font-size: 17px;
        line-height: 105%;
        padding: 0 10px 10px;
      }

      .location__address {
        font-size: 13px;
        color: @color_b2;
        line-height: 110%;
        padding: 0 10px 16px;
      }

      &:hover {
        img {
          transform: scale(1.08);
          filter: grayscale(0);
        }

        transform: scale(1.02);
        z-index: 2;
      }
    }
  }
}

// Video
.training__video-wrap{
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: @br;
  padding-bottom: 56.25%;
  background-color: @color_w;
  .box-shadow;
  margin-bottom: @gap * 2;

  .video__media{
    position: absolute;
    top: -1%;
    left: 0;
    object-fit: cover;
    width: 102%;
    height: 102%;
    border:none;
  }
  .video__button{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 68px;
    height: 48px;
    border: none;
    transform: translate(-50%, -50%);
    //display: flex;
    padding: 0;
    background: transparent;
    align-items: center;
    align-content: center;
    justify-content: center;
    outline: none;
    display: none;

    svg{
      width: 100%;
      height: auto;
    }

    &-icon{
      fill: #fff;
    }
    &-shape{
      opacity: .8;
      fill: #f00;
      transition: opacity 0.2s;
    }
  }



  &:hover .video__button-shape,
  .video__button:focus .video__button-shape{
    opacity: 1;
  }

  &.video--enabled .video__button{
    display: flex;
  }

  &.video--enabled{
    cursor: pointer;
  }
}

// ---------------------------------- PEST LIBRARY
.library {
  display: flex;
  margin-left: -@gap/2;
  margin-right: -@gap/2;
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;

  .library__item {
    display: flex;
    padding-left: @gap/2;
    padding-right: @gap/2;
    width: 33.33%;

    .pest__box {
      display: flex;
      flex-direction: column;
      width: 100%;
      .box-shadow;
      border-radius: @br;
      padding-top: 16px;
      position: relative;
      margin-bottom: @gap;
      text-decoration: none;
      color: @color_b1;

      &::after{
        content: '';
        display: block;
        width: calc(100% - @br * 2);
        height: 3px;
        position: absolute;
        bottom: 0;
        z-index: 2;
        left: 50%;
        background: @color_1;
        transform: translateX(-50%);
        clip-path: polygon(4% 0%, 96% 0%, 100% 100%, 0% 100%);
        transition: all .2s ease-in-out;
      }

      .pest__box__image {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 57%;
        border-radius: @br @br 0 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover !important;
          width: 100%;
          height: 100%;
          font-family: 'object-fit: cover;';
          transition: all .2s ease-out;
        }
      }

      .pest__box__name {
        font-size: 19px;
        display: flex;
        padding: 16px 16px 22px;
        text-align: center;
        min-height: 46px;
        align-items: center;
        align-content: center;
        justify-content: center;
      }

      &:hover {
        .pest__box__image {
          img{
            transform: scale(1.05);
          }
        }

        &::after{
          height: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: 620px),
  only screen and (max-device-width: 620px) {
    .library__item {
      width: calc(100% / 2);

    }
  }

  @media only screen and (max-width: 520px),
  only screen and (max-device-width: 520px) {
    .library__item {
      .pest__box__name{
        font-size: 17px !important;
      }
    }
  }
}

.content-padding__top-bottom {
  padding-top: @gap;
  padding-bottom: @gap * 2;
}


// ---------------------------------- BROCHURES
.brochure {
  display: flex;
  margin-left: -@gap/2;
  margin-right: -@gap/2;
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;

  &-item {
    display: flex;
    padding-left: @gap/2;
    padding-right: @gap/2;
    width: 25%;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 33.33%;
    }
    @media only screen and (max-width: 680px),
    only screen and (max-device-width: 680px) {
      width: 50%;
    }
    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 100%;
    }

    & > div{
      display: flex;
      flex-direction: column;
      width: 100%;
      .box-shadow;
      border-radius: @br;
      position: relative;
      margin-bottom: @gap;
      text-decoration: none;
      color: @color_b1;
      flex-direction: column;

      .brochure-item__text{
        flex: 1 1 auto;
        padding: 20px 20px 10px 30px;

        h4{
           font: @f_bold 17px/120% @font !important;
          margin-bottom: 5px !important;
        }

        p{
          font-size: 16px;
          line-height: 125%;
          margin-bottom: 5px;
        }

        > a{
          font-size: 13px;
          line-height: 120%;
          position: relative;
          padding-left: 20px;
          display: flex;

          &::after{
            content: '\f56d';
            .far;
            color: @color_b4;
            font-size: 17px;
            position: absolute;
            left: 0;
            top: 0;
            transition: all .2s linear;
          }

          &:hover{
            &::after{
              color: @color_2;
            }
          }


        }

      }

      .brochure-item__links{
        display: flex;
        padding-top: 2px;
        flex-direction: row;
        height: 40px;
        justify-content: flex-end;
        box-shadow: inset  0 2px 0 0 @color_b6;
        padding-right: 20px;

        > a{
          display: flex;
          align-content: center;
          align-items: center;
          padding: 5px 10px;
          text-decoration: none;
          color: @color_b1;

          &:hover{
            color: @color_2;

            &::before{
              color: @color_2;
            }
          }

          &::before {
            content: '';
            width: 30px;
            height: 30px;
            display: flex !important;
            align-content: center;
            align-items: center;
            justify-content: center;
            .far;
            color: @color_b4;
          }

          &.link__pdf::before{
            content:'\f02f';
          }
          &.link__more::before{
            content:'\f550';
          }
        }
      }

      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 10px;
        z-index: -2;
        opacity: .2;

        background: url("@{img}logo__symbol.png")  100% 50% no-repeat;
        background-size: 40px auto !important;
        transition: all .2s linear;
      }

      &:hover {
        &::after {
          width: 25px;
          opacity: .25;
        }
      }
    }
  }
}

// ---------------------------------- RESOURCES
.resources {
  display: flex;
  margin-left: -@gap/2;
  margin-right: -@gap/2;
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;

  &-item {
    display: flex;
    padding-left: @gap/2;
    padding-right: @gap/2;
    width: 25%;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 33.33%;
    }
    @media only screen and (max-width: 680px),
    only screen and (max-device-width: 680px) {
      width: 50%;
    }
    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 100%;
    }

    & > div{
      display: flex;
      flex-direction: column;
      width: 100%;
      .box-shadow;
      border-radius: @br;
      position: relative;
      margin-bottom: @gap;
      padding: @gap*1.5 @gap*1.5 @gap @gap*1.5;
      text-decoration: none;
      color: @color_b1;
      flex-direction: column;

      .resources-item__title{
        h2{
          font-weight: @f_bold;
          font-size: 15px;
          line-height: 115%;
          margin-bottom: 5px;
        }
      }

      .resources-item__contact {
        display: flex;
        flex-direction: column;
        height: auto;

        > *{
          display: flex;
          font: @f_reg 13px/120% @font;
          align-content: center;
          align-items: center;
          padding: 5px 0;
          text-decoration: none;
          color: @color_b1;
          margin-bottom: 1px !important;
        }

        > a{
          color: @color_1;
          text-decoration: underline;
          &:hover{
            color: @color_2;

            &::before{
              color: @color_2;
            }
          }
        }
      }

      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 10px;
        z-index: -2;
        opacity: .2;

        background: url("@{img}logo__symbol.png")  100% 50% no-repeat;
        background-size: 40px auto !important;
        transition: all .2s linear;
      }

      &:hover {
        &::after {
          width: 25px;
          opacity: .25;
        }
      }
    }

    &__date{
      margin-bottom: @gap;
    }

    &__links{
      display: flex;
      padding-top: 2px;
      flex-direction: row;
      height: 40px;
      justify-content: flex-end;
      box-shadow: inset  0 2px 0 0 @color_b6;
      padding-right: 0;

      > a{
        display: flex;
        align-content: center;
        align-items: center;
        padding: 5px 10px;
        text-decoration: none;
        color: @color_b1;

        &:hover{
          color: @color_2;

          &::before{
            color: @color_2;
          }
        }

        &::before {
          content: '';
          width: 30px;
          height: 30px;
          display: flex !important;
          align-content: center;
          align-items: center;
          justify-content: center;
          .far;
          color: @color_b4;
        }

        &.link__pdf::before{
          content:'\f02f';
        }
        &.link__more::before{
          content:'\f550';
        }
      }
    }
  }
}

// ---------------------------------- PRESS
.press{
  display: flex;
  margin-left: -@gap/2;
  margin-right: -@gap/2;
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;

  &-item {
    display: flex;
    padding-left: @gap/2;
    padding-right: @gap/2;
    width: 100%;

    & > div{
      display: flex;
      flex-direction: column;
      width: 100%;
      .box-shadow;
      border-radius: @br;
      position: relative;
      margin-bottom: @gap;
      padding: @gap*1.5  @gap*1.5  @gap  @gap*1.5;
      text-decoration: none;
      color: @color_b1;
      flex-direction: column;
    }

    &__title{
       font: @f_bold 17px/120% @font;
    }
    &__date{
       font: @f_reg 13px/120% @font;
      color: @color_b3;
      margin-bottom: 2px;
    }
    &__description{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* количество строк */
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: @gap;
      text-overflow: ellipsis;

      > p{
        margin-bottom: 0 !important;
      }
    }
    &__more{
      display: flex;
      justify-content: flex-end;
      padding-top: 2px;
      flex-direction: row;
      height: 40px;
      box-shadow: inset  0 2px 0 0 @color_b6;
      padding-right: 20px;

      a{
        display: flex;
        align-content: center;
        align-items: center;
        padding: 5px 0 0;
        text-decoration: none;
        color: @color_b1;

        &:after{
         content: '\f178';
          margin-left: 5px;
          .fal;
          color: @color_b3;
        }

        &:hover{
          color: @color_2;
          &:after {
            transform: translateX(10px);
          }
        }
      }
    }
  }
}

// ---------------------------------- IMG PREVIEWS BOX's
.img-previews {
  display: flex;
  margin-left: -@gap/2;
  margin-right: -@gap/2;
  align-content: stretch;
  align-items: stretch;
  flex-wrap: wrap;

  &__item {
    display: flex;
    padding-left: @gap/2;
    padding-right: @gap/2;
    width: 25%;

    @media only screen and (max-width: 960px),
    only screen and (max-device-width: 960px) {
      width: 33.33%;
    }

    @media only screen and (max-width: 680px),
    only screen and (max-device-width: 680px) {
      width: 50%;
    }

    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
      width: 100%;
    }

    & > a{
      display: flex;
      flex-direction: column;
      width: 100%;
      .box-shadow;
      border-radius: @br;
      position: relative;
      margin-bottom: @gap;
      text-decoration: none;
      color: @color_b1;
      flex-direction: column;

      &:hover{
        img{
          transform: scale(1.05);
          filter: grayscale(0) brightness(100%);
        }
        p{
          color: @color_2;
        }
      }
    }

    &__img{
      border-radius: @br @br 0 0;
      overflow: hidden;
      position: relative;
      padding-bottom: 55.6%;


      > img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        transition: all .2s linear;
        filter: grayscale(50%) brightness(92%);
      }
    }

    &__text{
      padding: 20px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: stretch;

      p{
        font-size: 15px;
        flex: 1 1 auto;
        margin-bottom: 10px;
        transition: all .2s linear;
      }

      time{
        font-size: 15px !important;

        &::before{
          content: '\f783';
          color: @color_b4;
          .far;
        }
      }
    }

  }
}

// ---------------------------------- library-list
.library-list{
  padding-right: 0;

  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {
    padding-right: 40px;
  }
  
  &__item{
    margin-bottom: @gap;

    &__title{
      border-bottom: 1px solid @color_1;
      margin-bottom: 5px;

      > div{
        display: inline-flex;
        margin-right: auto;
        padding: 5px 20px;
        background: @color_1;
        border-radius: 0 @gap 0 0;
        color: @color_w;
        text-transform: uppercase;
        font-weight: @f_sbold;

      }
    }
  }
}
.library-nav{
  position: fixed;
  right: 2px;
  top: 120px;
  display: flex;
  flex-direction: column;
  background: @color_w;
  border-radius: @br;
  z-index: 99;

  > a{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    color: @color_b1;
    margin-bottom: 2px;

    &:hover{
      color: @color_w;
      background: @color_2;
    }

    &.active{
      color: @color_w;
      background: @color_1;
    }
  }
}


// ---------------------------------- LICENSES and Certifications
.section__licenses-and-certifications{
  padding-top: @gap*2;
  padding-bottom: @gap*2;

  .logos-box{
    display: flex;
    margin-left: -@gap;
    margin-right: -@gap;
    flex-wrap: wrap;


    &__item{
      //border: 1px solid red;
      width: 50%;
      padding-left: @gap;
      padding-right: @gap;
      margin-bottom: @gap;
      position: relative;

      @media only screen and (max-width: 639.9px),
      only screen and (max-device-width: 639.9px) {
        width: 100%;
      }

      > div{
        border-radius: @gap;
        box-shadow: @shadow_s;
        padding: @gap*2;
        text-align: left;
        height: 100%;

        @media only screen and (max-width: 959.9px),
        only screen and (max-device-width: 959.9px) {
          padding: @gap;
        }

      }
    }
    &__imgs{
      display: flex;
      flex-wrap: wrap;

    }
    &__img{
      flex: 1 1 160px;

      @media only screen and (max-width: 639.9px),
      only screen and (max-device-width: 639.9px) {
        flex: 1 1 120px;
      }

      img{
        width: 100%;
        max-width: 220px;
      }
    }
    &__title{
      p{
        font-size: 14px;
        font-weight: @f_sbold;
      }
    }
  }
}

// ---------------------------------- 404
.section__error{
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: @gap*2 @gap !important;

  .section__error__number{
    font-size: 120px;
    color: @color_1;
    font-weight: @f_bold !important;
    line-height: 150%;
    display: flex;
    text-align: center;
  }

  .error__details{
    text-align: center;
  }

}


.explained-container{
  padding: 20px;
  border-radius: @gap*2;
  .box-shadow;
  margin-bottom: @gap;


  &__head{
    display: flex;
    margin-bottom: @gap;
  }
  &__img{
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 1px solid @color_b5;
    flex: 0 0 150px;
    box-shadow: 0 10px 40px -2px rgba(@color_b2, .1);

    > img{
      width: 100%;
    }
  }
  &__title{
    width: 100%;
  }
  &__info{
    padding-left: @gap*2;
    display: flex;
    flex-direction: column;
  }
  &__characteristics{
    display: flex;

    > *{
      padding-right: @gap;

      sub{
        display: inline-flex;
        color: @color_b3;
        font: 300 13px/100% Roboto,Helvetica,Arial,sans-serif !important;
        bottom: 0;
        margin-bottom: 10px !important;
      }
      div{
        line-height: 100%;
      }
    }
  }
}

// SLIDER
.big-slider {
  position: relative;
  width: auto;
  .radius(@br);
  margin-bottom: @gap;
  overflow: hidden;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;

  p {
    margin-bottom: 0;
  }

  &::after {
    content: @fa-var-search-plus;
    font-size: 20px;
    .fas;
    color: @color_1;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    .anim_on(0.3s);
    .anim-delay(1.0s);
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    margin-right: 15px;
    margin-left: 15px;
  }

  a {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 70vh;
    padding-bottom: 66.6%;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      position: absolute;
      -o-object-fit: cover !important;
      object-fit: cover !important;
      width: 100%;
      height: 100%;
      font-family: 'object-fit: cover;';
    }
  }
}

// slick tools
button.slick-arrow {
  display: block;
  top: 50%;
  width: 40px;
  height: 40px;
  z-index: 8;
  background: rgba(@color_1, .5) !important;
  .radius(@br);
  .centerxy;
  .transform(translateY(-50%));

  &:hover {
    background: @color_2;
  }

  &::before {
    position: relative;
    .fas;
  }
}

&:hover {
  button.slick-arrow {
    background: rgba(@color_1, .5) !important;
  }

}

button.slick-prev.slick-arrow {
  left: 5px;

  &::before {
    content: "\f053";
    left: -2px;
  }
}

button.slick-next.slick-arrow {
  right: 5px;

  &::before {
    content: "\f054";
    right: -2px;
  }
}

button.slick-lightbox-close {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  z-index: 8;

  &::before {
    content: @fa-var-times;
    position: relative;
    font-size: 22px;
    color: @color_1;
    .fas;
  }
}

.slick-lightbox {
  .slick-lightbox-slick-item-inner {
    width: 100%;

    img {
      width: 100%;
      max-width: 980px;
    }
  }
}



.columns__adaptive{
  display: flex;
  flex-wrap: wrap;

  > div{
    flex: 1 1 160px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  img{
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}


.pdf-download{
  display: flex;
  //flex-wrap: wrap;
  //border: 1px solid red;
  flex-direction: row;
  
  @media only screen and (max-width: 680px),
  only screen and (max-device-width: 680px) {
    flex-wrap: wrap;
  }

  &__image{
    //border: 1px solid red;
    margin-bottom: 0 !important;
    flex: 1 1 460px;
  }
  &__download{
    //border: 1px solid red;
    flex: 1 1 200px;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

// MOSQUITO SAN FRANCISCO

.neighborhoods-map{
  margin-bottom: @gap;
  display: flex;

  iframe{
    width: 100%;
    height: 70vh;
    max-height: 720px;
    min-height: 380px;
  }
}

.neighborhoods-guide-box{
  display: flex;
  flex-direction: column;
  border-radius: @br;
  box-shadow: @shadow_s;
  padding: @gap*1.5;
  margin-bottom: @gap;

  &__title{
     font: @f_sbold 20px/120% @font;
    margin-bottom: 10px;
  }
  &__content{
    display: flex;
    align-items: center;
    align-content: center;

    > *{
      margin-right: @gap;
      align-items: center;
      align-content: center;
    }
  }

}

.nbh-color{
  display: inline-flex;

  &::before{
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: @color_b3;
    margin-right: 4px;
    border-radius: 100%;
  }
  &[data-nbh-color='color-1']::before{
    background: #ffdb00;
  }
  &[data-nbh-color='color-2']::before{
    background: #e74c82;
  }
  &[data-nbh-color='color-3']::before{
    background: #627173;
  }
}

.img-wrap{


  .nbh-color{
    position: absolute;
    left: 8px;
    bottom: 8px;
    display: inline-flex;
    background: rgba(@color_b1, .7);
    color: @color_w;
    border-radius: @br;
    padding: 10px @gap;
    align-content: center;
    align-items: center;
  }
}

.mosquitosf-info{
  padding-top: @gap*2;
  padding-bottom: @gap*2;

  [class*='col-wrap__']{
    padding-left: @gap;
    padding-right: @gap;
  }



}

.help-us-section{
  margin-bottom: @gap;

  .help-us{
    display: flex;
    flex-wrap: wrap;
    margin-left: -@gap;
    margin-right: -@gap;

    &__item{
      width: 33.33%;
      position: relative;
      margin-bottom: @gap;
      padding-left: @gap;
      padding-right: @gap;
    }

    &__wrap{
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      align-items: center;
      border-radius: @br;
      box-shadow: @shadow_s;
      background: @color_w;
      padding: @gap*1.2;
    }

    &__icon{
      margin: auto;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;

      img{
        width: 112px;
        height: 112px;
      }
    }

    &__text{
      font: @f_reg 20px/120% @font;
      margin-bottom: @gap;
      color: @color_b2;
      flex: 1 1 auto;
    }

  }
}

.prev-one-slider {
  width: 100%;
  position: relative !important;

  .slick-list {
    //padding-bottom: 40px;
    overflow: visible;
  }

  &__item {
    display: flex;
    width: 100%;
    border-radius: @br;
    box-shadow: @shadow;

    .img-wrap {
      display: flex;
      overflow: hidden;
      position: relative;
      width: 100%;
      padding-bottom: 40%;
      border-radius: @br;

      @media only screen and (max-width: 740px),
      only screen and (max-device-width: 740px) {
        border-radius: @br/2;
      }

      img {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }
  }

  @media only screen and (max-width: 740px),
  only screen and (max-device-width: 740px) {

    .slick-next.slick-arrow{
      right: 9px;
    }
    .slick-prev.slick-arrow{
      left: 9px;
    }
  }
}

body.mobile .crumbs{
  overflow-x: auto;
}

.crumbs{
  background: @color_b6;
  overflow: hidden;


  & .resolution-wrap,
  & .resolution-text-wrap {
    display: flex;
    min-height: 36px;

    &>*{
      padding: 8px 8px 8px 0;
      display: flex;
      text-decoration: none;
      color: @color_b2;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      &:not(:last-child){
        &::after{
          content: '\f105';
          font-size: 16px;
          .fal;
          margin-left: 8px;
          color: @color_b2;
        }
      }

    }
    & > a:hover{
      box-shadow: inset 0 -1px 0 0 rgba(@color_1, 1);
    }
  }
}


.auto-columns{
  display: flex;
  flex-wrap: wrap;

  &[data-columns='1'] > *{
    width: 100%;
  }
  &[data-columns='2'] > *{
    width: 50%;
    @media only screen and (max-width: 539.99px),
    only screen and (max-device-width: 539.99px) {
      width: 100%;
    }
  }
  &[data-columns='3'] > *{
    width: 33.33%;
    @media only screen and (max-width: 859.99px),
    only screen and (max-device-width: 859.99px) {
      width: 50%;
    }
    @media only screen and (max-width: 539.99px),
    only screen and (max-device-width: 539.99px) {
      width: 100%;
    }
  }
  &[data-columns='4'] > *{
  width: 25%;
    @media only screen and (max-width: 959.99px),
    only screen and (max-device-width: 959.99px) {
      width: 50%;
    }
    @media only screen and (max-width: 539.99px),
    only screen and (max-device-width: 539.99px) {
      width: 100%;
    }

  }
}

.section-mosquito-partners{
  .auto-columns{
    &__item{
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }
  }
}