// TABLEE FLEX-BOX
.table {
  * {
    .anim_on(@speed)
  }


  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;

  width: 100%;
  //border: 1px solid red;


  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    //border: 1px solid greenyellow;

    flex-basis: 100%;


    &:first-child {
      > * {
        .radius(@br 0 0 @br);
      }
    }

    &:last-child {
      > * {
        .radius(0 @br @br 0);
      }
    }

    &:hover {
      > div {
        background: rgba(255, 255, 255, 0.02);
      }
    }

    > * {
      padding: 0 10px 0 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      font: @font_5;
      width: 100%;
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      margin-bottom: 3px;

      &:hover {
        color: @color_w;
      }

      &:not(:first-child) {
        background: @color_b3;
      }
    }
  }

}


// TABLEE 3 FLEX-BOX RESPONSIVE
/*
.table3 {
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  box-pack: justify;
  justify-content: space-between;
  font-size: 1rem;
  margin: 0.5rem;
  line-height: 1.5;
  *{
    border:1px solid red;
  }
}
.table3-header{
  display: none;
}
@media (min-width: 500px) {
  .table3-header {
    font-weight: 700;
  }
}
.table3-row {
  width: 100%;
}
@media (min-width: 500px) {
  .table3-row {
    display: flex;
    flex-flow: row nowrap;
  }
}
.table3-row-item {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  -ms-flex-preferred-size: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  padding: 0.5em;
  word-break: break-word;
}
.table3-row .table3-row-item:nth-of-type(1){
  flex-grow: 0.08;
}
.table3-row-item:before {
  content: attr(data-header);
  width: 30%;
  font-weight: 700;
}
@media (min-width: 500px) {
  .table3-row-item {
    padding: 0.5em;
  }
  .table3-row-item:before {
    content: none;
  }
}
.row-collection{
  height: 200px;
  overflow: auto;
  margin-right: -15px;
}
*/

// TABLEE 2 FLEX-BOX RESPONSIVE

.table2 {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 3px;

  > div { // table head & body

    > div { // table row
      display: table-row;
      width: 100%;

      > div { // table cell
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  > .table2_header {
    display: table-header-group;
    table-layout: fixed;
    background: @color_1;

    > div { // table row

      > div { // table cell
        padding: 10px;
        color: @color_w;

        &:first-child {
          .radius(@br 0 0 @br);
        }

        &:last-child {
          .radius(0 @br @br 0);
        }

      }
    }
  }

  > .table2_body {
    display: table-row-group;

    > div { // table row
      &:hover {
        position: relative;
        z-index: 5 !important;

        > div {
          .shadow(inset 0 0 500px 200px rgba(0, 0, 0, 0.03));
        }

      }

      > div { // table cell
        padding: 5px 10px;
        background: @color_b7;

        &:first-child {
          .radius(@br 0 0 @br);
        }

        &:last-child {
          .radius(0 @br @br 0);
        }

        > div { // FLEX-BOX wrapper
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-content: center;
          align-items: center;
        }
      }

      &.better_position {

        > div {
          background: @color_1;
          color: @color_w;
        }
      }
    }
  }
}

// TABLEE 2 RESPONSIVE 1
// -----------------------------------
.table2.resp1 {
  > .table2_header {
    display: none !important;
  }

  > .table2_body {

    > div { // table row

      > div { // table cell
        vertical-align: top;

        &::before {
          content: attr(data-thn) ":";
          display: block;
          width: 100%;
          opacity: 0.5;
          font-size: 13px;
        }
      }
    }
  }
}

// TABLEE 2 RESPONSIVE 2
// -----------------------------------
.table2.resp2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;

  > div { // table head & body

    > div { // table row
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      margin-bottom: 3px;
    }
  }

  > .table2_body {

    > div { // table row

      > div { // table cell
        display: block;
        width: 50%;

      }
    }
  }
}

.flex_table1{
  max-width: 640px;
  display: table;

  > *{
    display: table-row;

    &:nth-child(2n+1){
      background: @color_b7;
    }

    > *{
      display: table-cell;
      padding: 8px 10px;
    }
  }
}

// TABS = TABS
// =======================================
.tabs_container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

  *{
    //border: 1px solid red;
  }

  > .tab_nav{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    > *:hover{
      cursor: pointer;
    }
  }

  > .tabs_box{
    position: relative;

    > *{
      left: 0;
      top: 0;
      position: absolute;
      z-index: -1;
      background: @color_w;
    }

    > *.active{
      position: relative;
      z-index: 2;
    }

  }

}

.tabs_theme1{
  > .tab_nav{

    > *{
      > div{
        padding: 20px;
        .radius(@br @br 0 0 );
        .anim_on(0.2s);
        border: 1px solid @color_b6;
        border-bottom: 0;

        > i{
          font-size: 14px;
          margin-right: 10px;
          color: @color_1;
          opacity: 0.7;
        }
      }

      &:hover{
        > div {
          background: @color_b6 + 10%;
        }
      }

      &:not(.active){
        bottom: -3px;
        position: relative;

        > div{
          background: @color_b6;
          color: @color_b2;
        }
      }

      &.active{
        position: relative;
        z-index: 5;
        border: 1px solid @color_b6;
        border-bottom: 0;
        bottom: -1px;
        .shadow( 0 -10px 29px -9px rgba(20,50,0,0.08) );

        > div{
          background: @color_w;
          color: @color_b1;

          > i{
            opacity: 1;
          }
        }
      }
    }
  }

  > .tabs_box{
    position: relative;
    border: 1px solid @color_b6;

    > * {
      background: @color_w;
      .shadow(@shadow);
      .radius(0 0 @br @br);
      overflow: hidden;

      > * {
        padding: 20px;
      }
    }


  }
}
.table-section{
  padding-top: @gap*2;
  padding-bottom: @gap*2;
}

.table__scroll{
  display: inline-block;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
}
.table__theme-1{
  width: 100%;
  min-width: 960px;
  height: auto;

  > thead {
    background: @color_1;
    color: @color_w;
    line-height: 110%;

    td {
      vertical-align: middle;
      padding: @gap @gap;
    }
  }
  > tbody {
    tr{
      &:nth-child(2n+1){
        background: @color_b6;
      }
      td {
        vertical-align: middle;
        padding: @gap/2 @gap;
      }
    }
  }
}

.table-links-1{
  tbody tr td{
    &:nth-child(3),  &:nth-child(4){
      a{
        display: flex;
        width: 36px;
        height: 36px;
        max-width: 36px;
        max-height: 36px;
        overflow: hidden;
       text-indent: 300px;
        white-space: nowrap !important;
        font-size: 5px;
        position: relative;

        &::after{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: '';
          color: @color_b1;
          text-indent: 0 !important;
          .fal;
          font-size: 18px !important;
        }

        &:hover::after{
          color: @color_1;
        }
      }
    }

    &:nth-child(3) a{
      &::after {
        content: '\f0c7';
      }
    }
    &:nth-child(4) a{
      &::after{
        content: '\f06e';
      }
    }
  }
}
