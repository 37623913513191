// Стили Выделения на странице
::selection {
  background: @color_1;
  color: @color_w;
}

::-moz-selection {
  background: @color_1;
  color: @color_w;
}
// justify-content - align X
// align-items & align-content - align Y



// PAGE PARAMETERS
//=============================================================================
.main-containder {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  width: 100vw;
  max-width: 2200px;
  min-height: 100vh;
  margin: auto;
  overflow: hidden;

  > .page-content {
    flex: 1 1 auto;
  }

  > .main-footer {
    order: 99;
  }
}

@resolution: 1366px;
@resolutionText: 680px;
.resolution-wrap {
  display: block;
  left: 0;
  width: 100%;
  max-width: @resolution;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  margin: auto;
  padding: 0 20px;

  @media only screen and (max-width: 640px),
  only screen and (max-device-width: 640px) {
    padding: 0 13px;
  }
}
.resolution-text-wrap {
  display: block;
  left: 0;
  width: 100%;
  max-width: @resolutionText;
  min-width: 320px;
  height: auto;
  min-height: 100%;
  margin: auto;
  padding: 0 20px;

  @media only screen and (max-width: 640px),
  only screen and (max-device-width: 640px) {
    padding: 0 13px;
  }
}

// ЦВЕТА
//=============================================================================
// основные цвета:
@color_1: #1966CC;  // old #D82128
@color_2: @color_1 + 30%;
@color_3: #E6390E;
@color_4: #E6390E;

// градиент серого:
@color_b1: #1a1719;
@color_b2: #717878;
@color_b3: #bfc1c4;
@color_b4: #bfc1c4;
@color_b5: #dfe0e2;
@color_b6: #f2f3f5;
//@color_b6: #EFF4F6;
@color_b7: #f2f3f5;

@color_w: #fff;

// Основные шрифты
//=============================================================================
@font: 'Roboto', Helvetica, Arial, sans-serif;
html, body {
  font: @font_6;
  line-height: 140%;
  color: @color_b1;

}

@font_1: @f_sbold 35px/120% @font;
@font_2: @f_sbold 25px/120% @font;
@font_3: @f_sbold 23px/120% @font;
@font_4: @f_reg 25px/140% @font;
@font_5: @f_reg 21px/140% @font;
@font_6: @f_reg 17px/140% @font;
@font_7: @f_reg 15px/140% @font;
@font_8: @f_reg 13px/140% @font;
@font_9: @f_reg 11px/140% @font;

@f_reg: 300;
@f_sbold: 400;
@f_bold: 500;


// bind subjects
//=============================================================================
// BORDER RADIUS
@br: @elements_h / 2;
.radius(@br) {
  border-radius: @br;
}

// BOX SHADOW
.box-shadow {
  box-shadow: 0 4px 17px 0 rgba(0,76,175, .13);
}

// ВЫСОТА КНОПОК, ИНПУТОВ, И Др. ЭЛЕМЕНТОВ
//=============================================================================
@gap: 16px;
@elements_h: 46px;
@img: '/assets/layouts/img/';

// TEXT ALIGN
.ta_l {
  text-align: left !important;
}

.ta_r {
  text-align: right !important;
}

.ta_c {
  text-align: center !important;
}

// HOVER EFFECTS
.h_zoom(@zoom, @sp) {
  transform: scale(@zoom);
  transition: all @sp ease-out;
}

// NO SELECT TEXT
.no_select,
.no_select * {
  cursor: default;
  user-select: none !important;
}


// CUSTOM SCROLLBAR
//=============================================================================
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: @color_b7;
}

@scroll_thumb: @color_b3;
@scroll_thumb_s: rgba(@color_b5, 0);
::-webkit-scrollbar-thumb {
  background: @scroll_thumb;
  border-radius: @br;

  &:hover {
    background: @scroll_thumb - 20%;
  }

  &:active {
    background: @color_1;
  }
}


// ANIMATION HOVER LINKS and BUTTONS
//=============================================================================
select,
a, button,
*:before,
*:after,
[type="button"],
[type="submit"],
[type="reset"] {
  .anim_on(@speed);
}

a{
  color: @color_1;

  &:hover{
    color: @color_1 + 20%
  }
}


// ALIGN CENTER CSS
//=============================================================================
.imgfit {
  overflow: hidden;

  img {
    -o-object-fit: cover !important;
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
  }
}


// FLEX-BOX ALIGN
.centerxy {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerx {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centery {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}


// BUTTONS style
//=============================================================================
.btn__theme-1 {
  border: none;
  background: none;
  outline: none;
  .radius(@br);
  font: @font_6;
  .centerxy;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 20px;
  background: @color_1;
  text-align: center;
  text-decoration: none;
  color: @color_w;
  white-space: nowrap;
  .anim_on(@speed);

  > *:not(:last-child) {
    margin-right: 7px;
  }

}

.btn__theme-2 {
  .centerxy;
  width: auto;
  min-width: 120px;
  height: @elements_h;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  border: 1px solid @color_1;
  background: none;
  color: @color_1 !important;
  white-space: nowrap;
  .anim_on(@speed);
  .radius(@br);

  > *:not(:last-child) {
    margin-right: 7px;
  }

  &:active {
    transform: translateY(1px);
  }
}

.btn__theme-3 {
  .centerxy;
  width: auto;
  height: @elements_h;
  padding: 0 15px;
  text-align: center;
  text-decoration: none;
  border: 2px solid @color_w;
  color: @color_b1;
  background: @color_w;
  white-space: nowrap;
  .anim_on(@speed);
  .radius(@br);
  .shadow(@shadow);

  &:active {
    .transform(translateY(1px));
  }

}

[class*="style-btn__"] {
  display: flex;
  display: -ms-flexbox;
  .no_select;

  > * {
    i {
      margin-right: 10px;
    }
  }
}

.style-btn__1 {
  > * {
    .btn__theme-1;
  }
}

.style-btn__2 {
  > * {
    .btn__theme-2;
  }
}

.style-btn__3 { // white
  > * {
    .btn__theme-3;
  }
}


// HOVER not mobile
body:not(.mobile) {
  .style-btn__1 > *:not([disabled]):hover,
  .btn__theme-1:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_2;
    color: @color_w !important;
    .shadow(@shadow);
  }

  .style-btn__2 > *:not([disabled]):hover,
  .btn__theme-2:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_2;
    border-color: @color_2;
    color: @color_w !important;
  }

  .style-btn__3 > *:not([disabled]):hover,
  .btn__theme-3:not([disabled]):hover {
    cursor: pointer !important;
    background: @color_w;
    color: @color_b1 !important;
  }
}

.btns_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: stretch;
}

.btn_l {
  justify-content: flex-start !important;
}

.btn_c {
  justify-content: center !important;
}

.btn_r {
  justify-content: flex-end !important;

}


// BLOCKS WIDTH TEMPLATE
//=============================================================================
.max-width__1366 {
  max-width: 1366px !important;
  margin: auto;
}

.max-width__1200 {
  max-width: 1200px !important;
  margin: auto;
}

.max-width__960 {
  max-width: 960px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.max-width__840 {
  display: block !important;
  max-width: 840px !important;
  margin: auto;
}

.max-width__680 {
  display: block !important;
  max-width: 680px !important;

}

.max-width__600 {
  display: block;
  max-width: 600px !important;
  margin: auto;
}

.max-width__540 {
  display: block;
  max-width: 540px !important;
  margin: auto;
}

.max-width__320 {
  max-width: 320px !important;
  margin: auto;
}

.max-width__260 {
  max-width: 260px !important;
}

.stretch_w {
  width: 100% !important;
}

.stretch_wh {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.align_l {
  margin-right: auto !important;
}

.align_r {
  margin-left: auto !important;
}

.align_c {
  margin-left: auto !important;
  margin-right: auto !important;
}


// COLUMNS STANDART
//=============================================================================
.columns_2 {
  column-count: 2;
  .col_bb;
  @media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
    .columns_2 {
      column-count: 1;
      column-gap: 40px;
    }
  }
}

.columns_3 {
  column-count: 3;
  column-gap: @gap;
  margin-bottom: @gap*2;
  .col_bb;
}

.columns_4 {
  column-count: 4;
  column-gap: 20px;
  margin-bottom: 20px;
  .col_bb;
}

.col_bb { //block transfer
  -webkit-column-break-inside: avoid !important;
  page-break-inside: avoid !important; /* Makes effect only in Firefox */
  break-inside: avoid !important; /* IE10+, Opera 11.1—12.1 */

  > * {
    width: 100%;
  }
}


// Paddings margins
.gap-btm__1{margin-bottom: @gap;}
.gap-btm__2{margin-bottom: @gap*2;}
.gap-btm__3{margin-bottom: @gap*3;}
.gap-btm__4{margin-bottom: @gap*4;}


// scroll up button
// =======================================
#go-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: none;
  width: 40px;
  height: 40px;
  z-index: 99;

  > * {
    display: block;
    text-align: center;
    cursor: pointer;
    width: inherit;
    height: inherit;
    background: @color_1;
    border: 1px solid @color_1;

    collapse: @color_2;
    .radius(@br);
    .transform(scale(0.92));
    .shadow(@shadow);
    text-decoration: none;
    opacity: 0.6;
    .centerxy;

    &::after {
      content: @fa-var-arrow-up;
      font-size: 15px;
      .fal;
      .centerxy;
      color: @color_w;

    }

    &:active {
      transform: scale(0.92);
    }
  }
}


// 404 PAGE
// =======================================
.error-page{
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: auto;
  align-items: center;
  align-content: center;
  justify-content: center;

  .error-page__number{
    font-size: 68px;
    line-height: 68px;
    color: @color_1;
    font-weight: @f_bold;
    margin-bottom: 20px;
  }
  .error-page__description{
    margin-bottom: 20px;
  }


}


// STARS RATING
/*
.rating {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  //unicode-bidi: bidi-override;
  //direction: rtl;
  //width: 8em;

  > span{
    display: none;
    margin-left: 10px;
    font: @font_7;

    &::before{
      content: @fa-var-user;
      .fas;
      font-size: 13px;
      left: -3px;
      color: @color_b4;
    }
  }

  &[data-user-rate] span{
    display: inline-block;
  }

  >label {
    display: inline-block;
    font-size: 0;

    >input {
      position: absolute;
      left: -999999px;
    }

    &:before {
      position: relative;
      font-size: 16px;
      .fas;
      display: block;
      content: "\f005";
      color: @color_b5;
      //background: -webkit-linear-gradient(-45deg, #d9d9d9 0%, #b3b3b3 100%);
      //background: @color_b5;
      //-webkit-background-clip: text;
      //-webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }
}
.rating > label:hover:before,
.rating > label:hover ~ label:before,
.rating > label.selected:before,
.rating > label.selected ~ label:before {
  color: @color_4 !important;
  //background: @color_4;
  //background: -webkit-linear-gradient(-45deg, #fcb551 0%, #d69a45 100%);
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}
.rating > label:hover:before,
.rating > label:hover ~ label:before{
  color: @color_4 + 40% !important;
}

*/
.rating,
.rating_info {
  display: flex;
  flex-direction: row-reverse !important;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  //unicode-bidi: bidi-override;
  //direction: rtl;
  //width: 8em;
  margin-top: 5px;
  margin-bottom: 5px;

  white-space: nowrap !important;

  > span {
    display: none;
    margin-left: 10px;
    font: @font_7;

    &::before {
      content: @fa-var-user;
      .fas;
      left: -3px;
      color: @color_b5;
      margin-right: 4px;
    }
  }

  &[data-user-rate] span {
    display: inline-block;
  }

  > a {
    display: inline-block;
    margin-left: 5px;
    font: @font_7;
    color: @color_b1;
    text-decoration: none;
    border-bottom: 1px dashed @color_b1;
  }

  > div {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    top: -2px;


    > div {
      display: inline-block;
      margin-right: 2px;

      &:before {
        position: relative;
        font: @font_7;
        .fas;
        content: "\f005";
        color: @color_b5;
      }
    }
  }
}
.rating {
  > div {
    > div:hover:before,
    > div:hover ~ div:before,
    > div.selected:before,
    > div.selected ~ div:before {
      color: @color_4 !important;
      cursor: pointer;
    }

    > div:hover:before,
    > div:hover ~ div:before {
      color: @color_4 + 40% !important;
    }
  }
}
.rating_info {
  > div {
    > div.selected:before,
    > div.selected ~ div:before {
      color: @color_4 !important;
    }
  }
}


// SELECT BOX
.select_box {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: @elements_h;
  max-height: @elements_h;
  cursor: pointer;

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_w;

      -webkit-box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 1px 0 @color_b5, 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > *:not(:last-child) {
        .shadow(inset 0 -1px 0 0 @color_b5);
      }

      > * {
        &:first-child:after {
          .transform(translateY(-50%) scaleY(-1));
        }
      }
    }
  }

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
        cursor: pointer;
      }
    }
  }

  * {
    .anim_on(@speed);
  }

  > div {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    width: 100%;
    position: relative;
    height: auto;
    z-index: 10;

    > * {
      display: block;
      text-align: left;
      padding: 0 20px 0 8px;
      text-decoration: underline;
      height: 30px;
      font: @font_5;
      color: @color_b1;
      .centery;
      white-space: nowrap;

      &:hover {
        cursor: pointer !important;
      }

      &:first-child:after {
        content: "\f078";
        display: block;
        position: absolute;
        font-size: 11px;
        right: 5px;
        .fas;
        color: @color_b4;
        z-index: 0;
      }
    }
  }

}


// SELECT BOX DARK THEME
.select_box.select_box_theme1 {

  .radius(@br);
  background: @color_b1;

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
      }
    }
  }

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_b1;


      -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > * {
        color: @color_w;
      }

      > *:not(:last-child) {
        .shadow(inset 0 -1px 0 0 @color_b2);
      }

      > *:not(:first-child):hover {
        background: none;
        color: @color_1;
      }

      > * {
        &:first-child:after {
          .transform(translateY(-50%) scaleY(-1));
        }
      }
    }
  }

  * {
    .no_select;
    .anim_on(@speed);
  }

  > div {
    z-index: 10;
    .radius(@br);

    > * {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      text-align: left;
      padding: 0 50px 0 20px;
      min-height: @elements_h;
      font: @font_4;
      text-decoration: none;
      color: @color_b5;

      &:first-child:after {
        content: @fa-var-caret-down;
        display: block;
        position: absolute;
        font-size: 20px;
        top: @elements_h / 2;
        right: 25px;
        .fas;
        color: @color_b4;
      }
    }
  }
}


// SELECT BOX LIGHT THEME
.select_box.select_box_theme2 {

  .radius(@br);
  background: @color_b1;

  &:not(.active) {
    > div {
      > *:not(:first-child) {
        display: none;
      }
    }
  }

  &.active {
    > div {
      align-self: start;
      top: 0;
      background: @color_b1;


      -webkit-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 40px -6px rgba(0, 0, 0, 0.2);

      > * {
        color: @color_w;
      }

      > *:not(:last-child) {
        .shadow(inset 0 -1px 0 0 @color_b2);
      }

      > *:not(:first-child):hover {
        background: none;
        color: @color_1;
      }

      > * {
        &:first-child:after {
          .transform(translateY(-50%) scaleY(-1));
        }
      }
    }
  }

  * {
    .no_select;
    .anim_on(@speed);
  }

  > div {
    z-index: 10;
    .radius(@br);

    > * {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      text-align: left;
      padding: 0 50px 0 20px;
      min-height: @elements_h;
      font: @font_4;
      text-decoration: none;
      color: @color_b5;

      &:first-child:after {
        content: @fa-var-caret-down;
        display: block;
        position: absolute;
        font-size: 20px;
        top: @elements_h / 2;
        right: 25px;
        .fas;
        color: @color_b4;
      }
    }
  }
}


