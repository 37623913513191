//============================================================================
//+++++++++++++++++++++++++++++++++ HEADER +++++++++++++++++++++++++++++++++++
//============================================================================

.header-fixed .notice-popup__covid,
.header.notice-closed .notice-popup__covid{
  //transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  padding: 0 80px;
}

[class*='notice-popup_'] {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: auto;
  padding: @gap/2 @gap*2 @gap/2 @gap;
  max-height: 800px;
  background: @color_3;
  color: @color_w;
  z-index: 99;
  font: @font_8;
  //box-shadow: 0 10px 30px -5px rgba(@color_b1, .3);
  .anim_on(@speed);

  .close-notice-btn {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 7px;
    right: 0;
    align-items: center;
    align-content: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: @fa-var-times;
      .fas;
      color: @color_w;
    }
  }

  .notice__content {
    display: flex;
    max-height: 800px;
    overflow: hidden;
    .anim_on(@speed);

    a{
      color: @color_w;
    }
  }

  .btn-slide-notice {
    position: absolute;
    width: 100%;
    height: 36px;
    display: none;
    background: linear-gradient(0, rgba(@color_3, 1) 0%, rgba(@color_3, .98) 28%, rgba(@color_3, 0) 100%);
    left: 0;
    bottom: 0;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;

    > span {
      display: none;
      padding: 2px 10px;
      user-select: none;
      background: rgba(@color_w, .6);
      color: @color_3;
      border-radius: 6px 6px 0 0 ;
      .anim_on(@speed);

      &::after{
        .fas;
      }

      &:first-child::after{
        content: @fa-var-caret-down;
      }

      &:last-child::after{
        content: @fa-var-caret-up;
      }

      &:hover{
        cursor: pointer;
        background: rgba(@color_w, 1);
      }
    }

  }

  @media only screen and (max-width: 780px),
  only screen and (max-device-width: 780px) {
    font-size: 13px;

    &:not(.deployed){
      > .notice__content {
        padding: 10px 16px 0;
        max-height: 75px;
      }
      .btn-slide-notice {
        span:first-child{
          display: block;
        }
      }
    }
    &.deployed{
      > .notice__content {
        padding: 10px 16px 40px;
      }
      .btn-slide-notice {
        span:last-child{
          display: block;
        }
      }
    }
    .btn-slide-notice {
      display: flex;
    }

  }
}


// LOGO
.logo {
  display: block;
  width: 142px;
  height: 40px;
  margin-right: auto;

  svg {
    width: 100%;
    height: 100%;
  }

}
.svg-logo{
  shape-rendering:geometricPrecision;
  text-rendering:geometricPrecision;
  image-rendering:optimizeQuality;
  fill-rule:evenodd;
  clip-rule:evenodd;

  &__p1{
    fill: @color_1;
  }
  &__p2{
    fill: @color_b1;
  }
}

// BIG HEADER
// ====================================
.header.desktop-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 90;
  width: 100%;
  max-width: 2200px;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: @color_w;

  > .resolution-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 0px;
  }

  .header__pre-header{
    z-index: 3;
    background: @color_b1;
    color: @color_w;
    position: relative;
    width: 100%;
    display: flex;

    > .resolution-wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      height: 57px;
    }

  }

  .header__nav{
    display: flex;

    a{
      display: flex;
      height: 32px;
      padding: 0 10px;
      color: @color_w;
      text-decoration: none;
      align-content: center;
      align-items: center;
      justify-content: center;
      font: @f_reg 14px/120% @font;
    }

    .header__nav__action-btn a{
      background: @color_1;
      border-radius: @br;
      padding: 0 16px;

      &:hover{
        background: @color_1 + 10%;
      }
    }
  }

  .logo{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    z-index: 4;
    left: @gap;
    top: 79px;

    > a{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

  }

  .header__soc-links{
    display: flex;
    position: relative;
    left: 0;

    a{
      margin-right: 10px;
      text-decoration: none;
      align-content: center;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 24px;
      height: 24px;
      color: @color_w;
      font: @f_reg 14px/110% @font;

      .fab.fa-twitter::before{
        top: 1px;
        position: relative;
        content: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48H459.8L305.6 224.2L487 464H345L233.7 318.6L106.5 464H35.8L200.7 275.5L26.8 48H172.4L272.9 180.9L389.2 48ZM364.4 421.8H403.5L151.1 88H109.1L364.4 421.8Z" fill="white"/></svg>');

      }
    }
  }

  .language{
    display: inline-flex;
    margin-right: auto;
    margin-left: @gap*2;
    position: relative;

    a{
      display: inline-flex;
      text-transform: uppercase;
      color: @color_b3;
      padding: 0 10px;
      text-decoration: none;
      position: relative;

      &:not(:last-child){
        &::after{
          position: absolute;
          top:0;
          right: 0;
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background: @color_b3;
        }
      }

      &.active{
        color: @color_b1;
      }
    }
  }

  .menu-burger {
    display: none;
  }

  .header__header-menu{
    > .resolution-wrap{
      position: relative;
      align-content: center;
      align-items: center;
      justify-content: right;
      height: 82px;
    }
  }

  .menu {
    display: flex;
    position: absolute;
    right: @gap;
    top: 50%;
    transform: translateY(-50%);

    &__wrap{
      display: flex;
      align-items: center;
      align-content: center;
    }

    &__item {
      margin-left: @gap;
      position: relative;
      padding-top: 6px;
      padding-bottom: 6px;

      &.active{
        > a::before, > .menu__sub-h::before{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -16px;
          width: 100%;
          height: 4px;
          background: @color_1;
        }
      }

      > a, > .menu__sub-h{
        display: flex;
        height: 36px;
        align-content: center;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;
        font-size: 16px;
        color: @color_b1;
        transition: color .2s linear;

        i{
          &::before{
            color: @color_b1;
          }
          margin-right: 5px;
        }

        &:hover{
          color: @color_1;
          cursor: pointer;
          user-select: auto;
        }
      }

      > .menu__sub-h {
        &::after{
          content: @fa-var-angle-down;
          .fal;
          color: @color_b3;
          margin-left: 5px;
          transition: all .2s ease-out;
        }
      }

      .menu__sub{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: stretch;
        top: 48px;
        left: -15px;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-out;
        background: @color_w;
        transform-origin: 0 0 !important;
        transform: translateY(-10px) scaleY(0);
        min-width: 180px;
        box-shadow: @shadow;
        padding-bottom: @gap;
        z-index: 3;

        &::before{
          content:'';
          display: block;
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
          background: @color_w;
          position: absolute;
          z-index: -1;
          left: 10px;
          top: -4px;
        }

        > a{
          display: flex;
          padding: 13px 15px;
          text-decoration: none;
          color: @color_b1;
          font-size: 15px;
          line-height: 120%;
          position: relative;

          &:hover{
            color: @color_1;
          }

          &.active::before{
            content:'';
            position: absolute;
            width: 12px;
            height: 12px;
            background: @color_1;
            top: 50%;
            left: -7px;
            transform: translateY(-50%);
            border-radius: 100%;
            box-shadow:0 5px 20px 0 rgba(@color_1, .7);
          }
        }
      }

      &:hover {
        > .menu__sub-h {
          color: @color_b1 + 10%;

          &::after{
            transform: scaleY(-1);
            color: @color_1;
          }
        }
        .menu__sub{
          opacity: 1;
          visibility: visible;
          transform: translateY(0) scaleY(1);
        }
      }

      // login btn
      &.menu__action-btn a{
        background: @color_1;
        color: @color_w;
        border-radius: 36px;
        padding: 0 20px;
        transition: all .2s linear;

        i{
          &::before{
            color: @color_w;
          }
        }

        &:hover{
          background: @color_1 + 30%;
          box-shadow: 0 3px 13px 0 rgba(@color_1, .4);
        }
      }
    }

    &__link-main-page{
      display: none;
    }
  }
}

// MOBILE HEADER
// ====================================
.header.mobile-header {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 2200px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: @color_w;
  z-index: 90;
  box-shadow: @shadow;

  > .resolution-wrap {
    position: relative;
    display: flex;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
  }

  .header__pre-header{
    display: none;
  }

  .header__soc-links{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 50%;
    margin-top: @gap;
    transform: translateX(-50%);

    a{
      margin-right: 10px;
      text-decoration: none;
      align-content: center;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 36px;
      height: 36px;
      color: @color_w;
      font: @f_reg 20px/110% @font;
    }
  }

  .logo{
    left: 50%;
    top: 12px;
    position: absolute;
    transform: translateX(-50%);
    transition: all .3s linear;
    width: 120px;

    > a{
      position: relative;
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    svg{
      width: 90% !important;
      height: auto !important;
    }

  }

  .menu-burger{
    display: flex;
    width: 46px;
    height: 46px;
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);

    &:hover{
      cursor: pointer;
    }

    &::before,
    i,
    &::after{
      content: '';
      display: block;
      width: 60%;
      height: 2px;
      background: @color_1;
      position: absolute;
      transform-origin: 0 50%;
      left: 50%;
      transform: translateX(-50%);
      transition: all .2s ease-out;
    }

    &::before { top: 30%; }
    i { top: 50%; }
    &::after { top: 70%; }
  }

  .menu-search{
    display: flex;
    width: 46px;
    height: 46px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);

    a{
      display: flex;
      width: 46px;
      height: 46px;
      align-content: center;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      text-decoration: none;

      &:after{
        content: '\f002';
        .far;
      }
    }

  }

  .header__header-menu{
    width: 100%;
    height: 0;
    top: 60px;
    position: absolute;

    > .resolution-wrap{
      position: relative;
      align-content: center;
      align-items: center;
      justify-content: right;
      height: 0;
    }
  }

  .menu{
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    top: 0;
    //background: @color_b1;
    padding-top: 0;
    height: 100vh;
    left: 0;
    z-index: 2;
    flex: 1 1 auto;
    max-width: 288px;
    transition: all .3s ease-out;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;

    &::before{
      content: '';
      pointer-events: none;
      width: calc(100% - 10px);
      height: 60px;
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(@color_b1,0), rgba(@color_b1,1));
    }

    .menu__wrap{
      background: @color_b1;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 288px;
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      z-index: 2;
      box-shadow: 10px 10px 40px -7px rgba(0,0,0,0.3);

      .header__nav {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          position: relative;
          flex-direction: column;

          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: @color_b1 + 10%;
            z-index: 1;
          }


          > a {
            display: flex;
            align-items: center;
            align-content: center;
            position: relative;
            z-index: 2;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
            text-decoration: none;
            min-height: 50px;
            color: @color_w;

            &.active {
              &::after {
                content: '';
                display: block;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                width: 3px;
                height: 100%;
                background: @color_1;
              }
            }
          }
        }
      }

      .menu__item{
        display: flex;
        position: relative;
        flex-direction: column;

        &.active{
          > a::before,
          > .menu__sub-h::before{
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 3px;
            height: 100%;
            background: @color_1;
          }
        }

        &:not(:last-child){
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: @color_b1 + 10%;
            z-index: 1;
          }
        }

        > a, .menu__sub-h{
          display: flex;
          align-items: center;
          align-content: center;
          position: relative;
          z-index: 2;
          text-align: left;
          padding-left: 20px;
          padding-right: 20px;
          text-decoration: none;
          min-height: 50px;
          color: @color_w;

          i {
            display: flex;
            width: 30px;
            align-content: center;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @color_w;
          }

          &.active{
            &::after{
              content: '';
              display: block;
              pointer-events: none;
              position: absolute;
              right: 0;
              top: 0;
              width: 3px;
              height: 100%;
              background: @color_1;
            }
          }
        }

        .menu__sub{
          display: flex;
          position: relative;
          flex-direction: column;
          background: @color_b1 + 5%;

          > a{
            display: flex;
            text-decoration: none;
            color: @color_w;
            font-size: 13px;
            text-align: left;
            padding: 13px 13px 13px 22px;
            position: relative;

            &.active::before{
              content:'';
              position: absolute;
              width: 3px;
              height: 100%;
              background: @color_1;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              pointer-events: none;
            }

            &:not(:last-of-type){
              border-bottom: 1px solid rgba(@color_w, .05);
            }
          }
        }
      }

      &::after{
        content: '';
        display: block;
        width: 280px;
        height: 180px !important;
        min-height: 100px;
      }
    }
  }

  .language{
    display: inline-flex;
    margin-left: auto;
    position: relative;
    height: 20px;
    margin-right: -8px;

    a{
      display: inline-flex;
      text-transform: uppercase;
      color: @color_b3;
      padding: 0 8px;
      text-decoration: none;
      position: relative;

      &:not(:last-child){
        &::after{
          position: absolute;
          top:0;
          right: 0;
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background: @color_b3;
        }
      }

      &.active{
        color: @color_b1;
      }
    }
  }

  &.menu-active {
    .resolution-wrap {
      .menu-burger {
        &::before {
          top: 50%;
          left: 20%;
          transform: rotate(-40deg);
          width: 20px !important;
        }
        i {
          left: 70%;
          opacity: 0;
        }
        &::after {
          top: 50%;
          left: 20%;
          transform: rotate(40deg);
          width: 20px !important;
        }
      }
      .menu{
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// SMALL HEADRER
// ====================================
.header.header__small:not(.mobile-header) {
  box-shadow: @shadow;

  .header__pre-header{
    > .resolution-wrap {
      height: 36px;
    }
  }

  .header__nav{
    a{
      height: 28px;
      font: @f_reg 12px/120% @font;
    }
  }

  .logo{
    top: 42px;
  }

  .language{
    display: inline-flex;
    margin-right: auto;
    margin-left: @gap*2;
    position: relative;

    a{
      display: inline-flex;
      text-transform: uppercase;
      color: @color_b3;
      padding: 0 10px;
      text-decoration: none;
      position: relative;

      &:not(:last-child){
        &::after{
          position: absolute;
          top:0;
          right: 0;
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background: @color_b3;
        }
      }

      &.active{
        color: @color_b1;
      }
    }
  }

  .menu-burger {
    display: none;
  }

  .header__header-menu{
    > .resolution-wrap{
      height:51px;
    }
  }

  .menu {
    &__item {
      padding-top: 4px;
      padding-bottom: 4px;

      > a, > .menu__sub-h{
        font-size: 15px;
      }

      > .menu__sub-h {
        &::after{
          content: @fa-var-angle-down;
          .fas;
          color: @color_b3;
          margin-left: 5px;
          transition: all .2s ease-out;
        }
      }

      .menu__sub{
        > a{
          font-size: 14px;
        }
      }
    }
  }
}


.main_page .logo a{
  pointer-events: none !important;
}

