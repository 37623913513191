// ACCORDION BASE
.accordion {
  display: flex;
  flex-direction: column;
  //border: 1px solid red;

  .accordion__item{
    display: flex;
    flex-direction: column;
    //border: 1px solid red;

    .accordion__item__title{
      //border: 1px solid green;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: left;
      position: relative;

      &:hover{
        cursor: pointer;
      }

      &::after{
        content: '\f055';
        .far;
        position: absolute;
        right: 10px;
        top: 10px;
        color: @color_b3;
      }
    }
    .accordion__item__content{
      display: none;
      //border: 1px solid yellowgreen;
    }
  }
  .accordion__item.active{
    > .accordion__item__title{
      &::after{
        content: '\f056';
        color: @color_1;
      }
    }
    > .accordion__item__content{
      // display: block;
    }
  }
}

// accordion styles v1
.accordion__theme-one {
  margin-bottom: @gap *2;

  .accordion__item{
    background: @color_w;
    .box-shadow;
    border-radius: @br;
    margin-bottom: 3px;
    border: 1px solid @color_b6;

    .accordion__item__title{
      //border: 1px solid green;
      min-height: 46px;
      padding: 13px 40px 10px 16px;


      > *{
        font: @f_sbold 18px/117% @font !important;
        margin-bottom: 0 !important;
      }

      &::after{
        right: 14px;
        top: 14px;
      }
    }

    .accordion__item__content{
      display: none;
      padding: 3px 16px 3px 16px;

      > *{
        font-size: 15px !important;
        line-height: 125% !important;
      }
      //border: 1px solid yellowgreen;
    }
  }
  .accordion__item.active{
    > .accordion__item__title{
      &::after{
        content: '\f056';
        color: @color_1;
      }
    }
  }
}



