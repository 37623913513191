.footer {
  background: @color_b1;
  color: @color_b6;

  > .resolution-wrap{
    display: flex;
    padding-bottom: @gap*2;
    padding-top: @gap*2;
    position: relative;

    @media only screen and (max-width: 1023.99px),
    only screen and (max-device-width: 1023.99px) {
      flex-direction: column;
    }
  }

  &__site-map{
    flex: 1 1 auto;
    position: relative;
    z-index: 3;
    column-count: 3;
    column-gap: @gap * 2;
    margin-bottom: 20px;

    @media only screen and (max-width: 679.99px),
    only screen and (max-device-width: 679.99px) {
      column-count: 1;
    }

    &__item{
      display: flex;
      flex-direction: column;
      .col_bb;
      padding-bottom: 10px;
    }

    &__title{
      display: block;
      position: relative;
      z-index: 3;
      margin-bottom: 0;
      //border-bottom: 1px solid rgba(@color_b2, .2);

      a {
        display: flex;
        font-size: 18px;
        font-weight: @f_sbold;
        line-height: 105%;
        color: @color_b3 - 10%;
        text-decoration: none;
        padding: 10px 0;

        &:hover{
          color: @color_b4;
        }
      }
    }

    &__menu{
      display: flex;
      flex-direction: column;
      margin-top: 3px;

      > a{
        display: flex;
        font-size: 15px;
        line-height: 105%;
        color: @color_b3 - 25%;
        text-decoration: none;
        padding: 8px 20px 8px 0;
        position: relative;
        text-shadow: 0 0 5px @color_b1,  0 0 5px @color_b1,  0 0 5px @color_b1,  0 0 5px @color_b1 ;

        &::before{
          content: '';
          display: block;
          width: calc(100% - 20px);
          border-bottom: 1px dashed rgba(@color_b2, .3);
          position: absolute;
          top: 15px;
          z-index: -1;
        }

        &::after{
          content: '\f178';
          .fal;
          position: absolute;
          top: 8px;
          right: 0;
          color: @color_b2 - 25%;
        }

        &:hover{
          color: @color_b4;

          &::after{
            color: @color_w !important;
          }
        }
      }
    }

    &__sub-menu{
      display: flex;
      flex-direction: column;
      padding-bottom: 4px;

      > a{
        display: flex;
        font-size: 13px;
        line-height: 118%;
        color: @color_b2;
        text-decoration: none;
        padding: 5px 0 5px 5px;

        &::before{
          content: '-';
          margin-right: 2px;
          color: rgba(@color_b2, .5);
        }

        &:hover{
          color: @color_b4;
        }
      }
    }

    &__soc-networks{
      display: flex;
      padding-top: @gap;

      > *{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-decoration: none;
        border-radius: 32px / 2;
        background: @color_1;
        color: @color_w;
        width: 32px;
        height: 32px;
        margin-right: @gap;

        .fab.fa-twitter::before{
          top: 2px;
          position: relative;
          content: url('data:image/svg+xml,<svg width="19" height="19" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48H459.8L305.6 224.2L487 464H345L233.7 318.6L106.5 464H35.8L200.7 275.5L26.8 48H172.4L272.9 180.9L389.2 48ZM364.4 421.8H403.5L151.1 88H109.1L364.4 421.8Z" fill="white"/></svg>');

        }

        &:hover{
          background: @color_1 + 30%;
          color: @color_w;
        }
      }
    }
  }

  &__contacts{
    display: flex;
    flex-direction: column;
    padding-left: @gap * 2;
    position: relative;
    z-index: 3;
    flex: 1 0 280px;

    .footer__contacts-list{
      > *{
        margin-bottom: 10px;

        strong{
          font-size: 17px;
          font-weight: @f_sbold;
          color: @color_b3;
          margin-bottom: 5px;
        }

        p{
          font-size: 15px;
          font-weight: @f_reg;
          color: @color_b2;
          margin-bottom: 3px;
        }

        .phone_link{
          color: @color_b3;
          text-decoration: none;
          font-weight: @f_reg;
          white-space: nowrap;

          &:hover{
            color: @color_1;
          }

          &::before{
            content: '\f879';
            color: @color_1;
            margin-right: 10px;
            .fas;
          }
        }
      }
    }

    @media only screen and (max-width: 1023.99px),
    only screen and (max-device-width: 1023.99px) {
      padding-left: 0;
      flex: 1 1 auto;

      .footer__contacts-list{
        display: flex;
        margin-left: -@gap;
        margin-right: -@gap;

        > *{
          padding-left: @gap;
          padding-right: @gap;
        }
      }
    }
    @media only screen and (max-width: 679.99px),
    only screen and (max-device-width: 679.99px) {
      .footer__contacts-list{
        flex-wrap: wrap;

        >*{
          width: 100%;
          margin-bottom: @gap;
        }
      }

    }
  }

  &-beetle__1,
  &-beetle__2{
    z-index: 2;
    shape-rendering:geometricPrecision;
    text-rendering:geometricPrecision;
    image-rendering:optimizeQuality;
    fill-rule:evenodd;
    clip-rule:evenodd;
    position: absolute;
    fill: @color_b1 + 7%;
  }
  &-beetle__1{
    width: 230px;
    transform: rotate(-80deg) translateX(-50%);
    left: 45%;
    bottom: 25px;
  }
  &-beetle__2{
    width: 335px;
    transform: rotate(-20deg) translateX(-80%);
    left: 100%;
    bottom: -90px;
  }

  &-copyright{
    background: @color_b1 - 10%;
    position: relative;
    z-index: 4;

    .resolution-wrap{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      min-height: 40px;

      .footer-logo{
        display: flex;
        align-content: center;
        align-items: center;

        .svg-logo{
          height: 22px;

          .svg-logo__p2{
            fill: @color_b2;
          }
          .svg-logo__p1{
            fill: @color_b1 - 10%;
          }
        }
      }

      .copyright{
        font-size: 13px;
        color: @color_b2;
      }
    }
  }


  @media only screen and (max-width: 860px),
  only screen and (max-device-width: 860px) {
    .footer-beetle__1{
      display: none !important;
    }
  }

  @media only screen and (max-width: 440px),
  only screen and (max-device-width: 440px) {
    .footer-copyright{
      .resolution-wrap{
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: @gap;
      }
    }
  }
}