//============================================================================
//++++++++++++++++++++++++++++++ POP-UP WINDOWS ++++++++++++++++++++++++++++++
//============================================================================
body::after{
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(@color_b1 - 20%, .8);
  position: fixed;
  top: 0;
  left: 0;
  .anim_on(.2s);
  opacity: 0;
  visibility: hidden;
}
body.popup__opened {

  &::after{
    opacity: 1;
    visibility: visible;
  }

  .main-container{
    filter: blur(1px);
  }
}

.header{
  .anim_off;
}

.popup {
  position: fixed;
  .anim_on(.2s);
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  //background: rgba(@color_b1, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;

  .popup__container {
    width: 100%;
    min-height: 100%;
    padding: 30px 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup__window {
      position: relative;
      width: 100%;
      max-width: 680px;
      padding: @gap*2;
      background: @color_w;
      .radius(@br*2);
      .shadow(0 13px 60px -5px rgba(@color_b1 - 30%, 0.4));

      .anim_on(.2s);
      transform: perspective(600) translateY(40%) rotateX(10deg);

      .popup__title {
        margin-bottom: 15px;
      }
    }


    // POPUP CLOSE BTN
    .popup__close {
      text-decoration: none;
      .centerxy;
      .anim_on(0.2s);
      width: 32px;
      height: 32px;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 15;
      background: @color_1;
      .radius(@br);
      .shadow(0 3px 20px -1px rgba(@color_1 - 30%, 0.4));

      &:hover {
        cursor: pointer;
        background: @color_2;
      }

      &:active {
        .transform(translateY(1px));
      }

      &::before {
        content: @fa-var-times;
        font-size: 17px;
        color: @color_w;
        .fas;
      }
    }
  }

  &.popup__open {
    opacity: 1;
    visibility: visible;

    .popup__window {
      transform: perspective(600) translateY(0) rotateX(0);
    }
  }
}





